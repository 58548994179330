<template>
  <div class="w-full about">
    <button
      class="px-3 py-1 mb-4 text-lg text-gray-200 bg-gray-500 rounded hover:opacity-75"
      @click="editItem(about)"
      v-if="isAuthenticated === 'authorized'"
    >
      EDIT PAGE
    </button>
    <h1 class="mb-4 text-2xl uppercase">
      Working at Peace of Mind Concierge Services
    </h1>
    <div v-if="about && about.content" v-html="about.content"></div>
    <div class="w-full sm:w-1/2 lg:w-1/3">
      <div class="relative w-full mb-3 overflow-hidden pb-1/1">
        <img
          class="absolute object-cover w-full h-full"
          src="https://storage.googleapis.com/pomcs-com.appspot.com/amber-look-alike.jpg"
          alt="Portfolio One image"
        />
      </div>
    </div>
  </div>

  <admin-about
    :about="about"
    :toggleModal="toggleModal"
    v-if="about_show"
  ></admin-about>
</template>

<script>
import { ref, onBeforeMount, inject } from "vue";
import { getAllOfPageType, isAuthenticated } from "../firebase.js";
import AdminAbout from "@/admin/AdminAbout.vue";

export default {
  inject: ["global"],
  setup() {
    const about = ref({
      id: "0",
      page: "about",
      type: "working-at-pomcs",
      sortOrder: 0,
      content: "",
    });
    const about_show = ref(false);
    const selectedItem = ref({});
    onBeforeMount(() => {
      getAllOfPageType("about", "working-at-pomcs").then((data) => {
        if (data.length) about.value = data[0];
      });
    });
    const toggleModal = (section) => {
      eval(`${section}_show`).value = !eval(`${section}_show`).value;
    };
    const editItem = (item) => {
      const editorVars = ["content"];
      for (const e of editorVars) {
        if (item && item[e]) {
          item[e] = item[e]
            .replace(/\\n \+\n/g, "")
            .replace(/    /g, "")
            .replace(/\n/g, "");
        }
      }
      selectedItem.value = item;
      about.value = item;
      toggleModal("about");
    };
    return {
      about,
      about_show,
      getAllOfPageType,
      isAuthenticated,
      editItem,
      selectedItem,
      toggleModal,
      AdminAbout,
    };
  },
};
</script>

<style lang="scss">
.img-holder {
  max-width: 460px;
}
.header-description p {
  margin-bottom: 1rem;
}
.about p {
  margin-bottom: 1rem;
}
</style>
